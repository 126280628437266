<template>
    <div class="container-fluid mt-5">
            <section class="mb-4">
                 <div class="container">
                    <div class="large-12 medium-12 small-12 cell">
                        <label>Files
                            <input type="file" id="files" ref="files" multiple v-on:change="handleFileUploads()"/>
                        </label>
                      <button v-on:click="submitFiles()">Submit</button>
                    </div>
                  </div>
                <vue-bootstrap4-table class="" :rows="rows" :columns="columns" :config="config">
                  <template slot="paginataion-previous-button">
                      Previous
                  </template>
                  <template slot="paginataion-next-button">
                      Next
                  </template>
                </vue-bootstrap4-table>
            </section>
    </div>
</template>
<script>
import VueBootstrap4Table from 'vue-bootstrap4-table'
 
export default {
    data(){
        return {
            files: '',
            cooperative_id: 1,
            rows: [{
                    "id": 1,
                    "name": {
                        "first_name": "Vladimir",
                        "last_name": "Nitzsche"
                    },
                    "address": {
                        "country": "Mayotte"
                    },
                    "email": "franecki.anastasia@gmail.com",
                },
                {
                    "id": 2,
                    "name": {
                        "first_name": "Irwin",
                        "last_name": "Bayer"
                    },
                    "age": 23,
                    "address": {
                        "country": "Guernsey"
                    },
                    "email": "rlittle@macejkovic.biz",
                },
                {
                    "id": 3,
                    "name": {
                        "first_name": "Don",
                        "last_name": "Herman"
                    },
                    "address": {
                        "country": "Papua New Guinea"
                    },
                    "email": "delia.becker@cormier.com",
                }],
            columns: [{
                    label: "id",
                    name: "id",
                    filter: {
                        type: "simple",
                        placeholder: "id",
                        filterOnPressEnter: true,
                    },
                    sort: true,
                },
                {
                    label: "First Name",
                    name: "name.first_name",
                    filter: {
                        type: "simple",
                        placeholder: "Enter first name"
                    },
                    sort: true,
                },
                {
                    label: "Email",
                    name: "email",
                    sort: true,
                },
                {
                    label: "Country",
                    name: "address.country",
                    filter: {
                        type: "simple",
                        placeholder: "Enter country"
                    },
                }],
            config: {
                checkbox_rows: false,
                rows_selectable: true,
                card_title: "Vue Bootsrap 4 advanced table",
                global_search: {
                    placeholder: "Enter custom Search text",
                    visibility: true,
                    case_sensitive: false,
                    searchOnPressEnter: false,
                    searchDebounceRate: 1000,
                },
                pagination: true,
                num_of_visibile_pagination_buttons: 7,
                per_page: 5, 
                per_page_options:  [5,  10,  20,  30],
                show_refresh_button: true,
                show_reset_button: true,
            }
        }
    },
    methods:{
        handleFilesUpload(){
            this.files = this.$refs.files.files;
        },
        submitFiles(){
            let formData = new FormData();
            
            for( var i = 0; i < this.files.length; i++ ){
                let file = this.files[i];
                formData.append('files[' + i + ']', file);
            }
            formData.append('cooperative_id', 1);
            axios
                .post("/files", formData, { headers: { "Content-Type": "multipart/form-data" } })
                .then((response)=>{
                    console.log(response)
                });
        },
    },
    components: {
        VueBootstrap4Table
    }
}
</script>

