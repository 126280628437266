<template>
    <div id="page-inner">
        <div class="col-md-12">
            <h1 class="page-head-line">Update Details</h1>
            <h1 class="page-subhead-line">* are required fields </h1>
        </div>
        <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="panel panel-info">
                    <div class="panel-heading">
                       {{cooperative.name}} Details
                    </div>
                    <div class="panel-body">
                        <form role="form">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label class="form-label" >Cooperative Name</label>
                                        <input type="text"  class="form-control" v-model="cooperative.name"/>
                                        <span v-if="errors.name" class="text-danger">{{errors.name[0]}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="form-label">CDA Registration Number</label>
                                        <input type="text" class="form-control" v-model="cooperative.cda"/> 
                                        <span v-if="errors.cda" class="text-danger">{{errors.cda[0]}}</span>
                                    </div>
                                </div>
                                <div class="col-md-4"">
                                    <div class="form-group">
                                        <label class="form-label">CIN Number</label>
                                        <input type="text"  class="form-control" v-model="cooperative.cin"/>
                                        <span v-if="errors.cin" class="text-danger">{{errors.cin[0]}}</span>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="form-label">Type of Cooperative</label>
                                        <select class="form-control" v-model="cooperative.type_id">
                                            <option value="" disabled selected>Choose Type</option>
                                            <option v-for="(type, i) in types" :value="type.id">{{type.name}}</option>
                                        </select>
                                        <span v-if="errors.type_id" class="text-danger">{{errors.type_id[0]}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">BIR CTE Validity</label>
                                        <input type="date" class="form-control" v-model="cooperative.bir"/>
                                        <span v-if="errors.bir" class="text-danger">{{errors.bir[0]}}</span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">TIN</label>
                                        <input type="text"  class="form-control" v-model="cooperative.tin"/>
                                        <span v-if="errors.tin" class="text-danger">{{errors.tin[0]}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label class="form-label" >About Us</label>
                                        <textarea class="form-control" rows="6" v-model="cooperative.about_us"></textarea>
                                    </div>
                                </div>
                            </div>
                            <h4>Additional Information</h4>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">Number of Staff</label>
                                        <input type="text" class="form-control" v-model="cooperative.no_staff"/>
                                        <span v-if="errors.no_staff" class="text-danger">{{errors.no_staff[0]}}</span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">Number of Volunteers</label>
                                        <input type="text"  class="form-control" v-model="cooperative.no_volunteers"/>
                                        <span v-if="errors.no_volunteers" class="text-danger">{{errors.no_volunteers[0]}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">Address</label>
                                        <input type="text" class="form-control" v-model="cooperative.location" placeholder="Location" />
                                        <span v-if="errors.location" class="text-danger">{{errors.location[0]}}</span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">Select Barangay</label>
                                        <Select2 v-model="cooperative.barangay_id" :options='barangays'></Select2>
                                        <span v-if="errors.barangay_id" class="text-danger">{{errors.barangay_id[0]}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">Contact Number</label>
                                        <input type="text" class="form-control" v-model="cooperative.contact_no"/>
                                        <span v-if="errors.contact_no" class="text-danger">{{errors.contact_no[0]}}</span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">Email Address</label>
                                        <input type="email"  class="form-control" v-model="cooperative.email"/>
                                        <span v-if="errors.email" class="text-danger">{{errors.email[0]}}</span>
                                    </div>
                                </div>
                            </div>
                            <h4>Others</h4>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                       <textarea class="form-control" rows="6" v-model="cooperative.others"></textarea>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" class="btn btn-info btn-block mb-4"  @click.prevent="updateButton(cooperative.id)" id="btnUpdate">Update</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="panel panel-info">
                    <div class="panel-heading">
                       Update the image of your cooperative building
                    </div>
                    <div class="panel-body">
                        <div class="form-group text-center" v-if="cooperative.cooperative_pic != null && remove_image != true">
                            <img :src='"/upload/cooperative/"+cooperative.cooperative_pic' style="width: 750px; max-width: 415px; height: auto; display: block; display: block; margin-left: auto; margin-right: auto; width: 50%;"/>
                            <button class="btn btn-danger" v-on:click="remove_pic" id="btnRemoveLogo">Remove</button>
                        </div>
                        <div class="form-group" v-else>
                            <div class="text-center">
                                <div class="fileupload fileupload-new" data-provides="fileupload">
                                    <div class="fileupload-new thumbnail" style="width: 200px; height: 150px;"><img src="/img/demoUpload.jpg" alt="" /></div>
                                    <div class="fileupload-preview fileupload-exists thumbnail" style="max-width: 200px; max-height: 150px; line-height: 20px;"></div>
                                    <div>
                                        <span class="btn btn-file btn-primary"><span class="fileupload-new">Select Image</span><span class="fileupload-exists">Change</span><input type="file" id="cooperative_pic" ref="cooperative_pic" v-on:change="handleLogoUpload()" single/></span>
                                        <a href="#" class="btn btn-info fileupload-exists" id="imageUpdate" @click.prevent="updateImage(cooperative.id)">Upload</a>
                                        <a href="#" class="btn btn-danger fileupload-exists" data-dismiss="fileupload" v-on:click="cancel_pic">Cancel</a>
                                        <span v-if="errors.cooperative_pic" class="text-danger">{{errors.cooperative_pic[0]}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: [
            'cooperative'
        ],
        data(){
            return{
                errors:[],
                barangays:[],
                types:[],
                cooperative_pic:[],
                remove_image:false
            }
        },
        mounted(){
            this.getBarangays();
            this.getTypes();
        },
        methods:{
            handleLogoUpload(){
                this.cooperative_pic = this.$refs.cooperative_pic.files[0];
            },
            getBarangays(){
                axios.get('/api/barangays').then(response => {
                    this.barangay = response.data
                    var data = this.barangay.map(function(item){
                        return {
                            id : item.id,
                            text: item.name
                        }
                    }.bind(this));
                    this.barangays = data;
                })
            },
            remove_pic(){
                this.remove_image = true;
            },
            cancel_pic(){
                this.remove_image = false;
            },
            getTypes(){
                axios.get('/api/types').then((response)=>{
                    this.types = response.data;
                }).catch((error) =>{
                    console.log(error)
                })
            },
            updateButton(id){
                $("#btnUpdate").html("Updating ...").attr("disabled", true);
                axios.put('/api/cooperatives/'+id, this.cooperative).then((response)=>{
                    swal.fire(
                        'Congratulations!',
                        'You successfully updated the cooperative details',
                        'success'
                    )
                $("#btnUpdate").html("Update").attr("disabled", false);
                }).catch(error => {
                    if (error.response.status == 422) {
                        this.errors = error.response.data.errors
                        $("#btnUpdate").html("<i class='fa fa-times'></i> Something went wrong, Please try again!").attr("disabled", false);
                    }
                })
            },
            updateImage(user_id){
                $("#imageUpdate").html("Updating ...").attr("disabled", true);
                let formData = new FormData();
                formData.append('cooperative_pic', this.cooperative_pic);
                axios.post( '/api/upload_coop_pic/'+user_id,
                        formData,
                    {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then(response =>{
                    swal.fire(
                        'Congratulations!',
                        'You successfully updated the image',
                        'success'
                    )
                    this.cooperative.cooperative_pic = response.data.cooperative_pic
                    $("#imageUpdate").html("Submit").attr("disabled", false);
                }).catch(error=>{
                    if (error.response.status == 422) {
                        this.errors = error.response.data.errors
                    }
                    $("#imageUpdate").html("<i class='fa fa-times'></i> Error!").attr("disabled", false);
                });
            }
        }
    }
</script>
