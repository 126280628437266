<template>
    <div id="page-inner">
        <div class="col-md-12">
            <h1 class="page-head-line">Board of Directors</h1>
            <h1 class="page-subhead-line">* are required fields </h1>
        </div>
        <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="panel panel-primary">
                    <div class="panel-heading">
                       Add Officer
                    </div>
                    <div class="panel-body">
                        <form role="form">
                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <div class="text-center">
                                            <div class="fileupload fileupload-new" data-provides="fileupload">
                                                <div class="fileupload-new thumbnail" style="width: 200px; height: 150px;"><img src="/img/demoUpload.jpg" alt="" /></div>
                                                <div class="fileupload-preview fileupload-exists thumbnail" style="max-width: 200px; max-height: 150px; line-height: 20px;"></div>
                                                <div>
                                                    <span class="btn btn-file btn-primary"><span class="fileupload-new">Select image</span><span class="fileupload-exists">Change</span><input type="file" id="officer_pic" ref="officer_pic" v-on:change="handlePicUpload()" single/></span>
                                                    <a href="#" class="btn btn-danger fileupload-exists" data-dismiss="fileupload">Remove</a>
                                                    <span v-if="errors.officer_pic" class="text-danger">{{errors.officer_pic[0]}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label class="form-label">Prefix *</label>
                                        <input type="text" class="form-control" placeholder="eg. Mr./Mrs./Atty./Engr. etc." v-model="prefix" />
                                         <span v-if="errors.prefix" class="text-danger">{{errors.prefix[0]}}</span>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label class="form-label">First Name *</label>
                                        <input type="text" class="form-control" v-model="first_name"/>
                                         <span v-if="errors.first_name" class="text-danger">{{errors.first_name[0]}}</span>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label class="form-label">Middle Initial</label>
                                        <input type="text" class="form-control"  v-model="middle_name"/>
                                         <span v-if="errors.middle_name" class="text-danger">{{errors.middle_name[0]}}</span>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label class="form-label">Last Name *</label>
                                        <input type="text" class="form-control"  v-model="last_name"/>
                                         <span v-if="errors.last_name" class="text-danger">{{errors.last_name[0]}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4"">
                                    <div class="form-group">
                                        <label class="form-label">Position *</label>
                                        <select class="form-control" v-model="position_id">
                                            <option value="" disabled selected>Select</option>
                                            <option v-for="(position, i) in positions" :value="position.id">{{position.name}}</option>
                                        </select>
                                        <span v-if="errors.position" class="text-danger">{{errors.position[0]}}</span>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="form-label">Contact Number *</label>
                                        <input type="text" class="form-control"  v-model="contact_num"/>
                                         <span v-if="errors.contact_num" class="text-danger">{{errors.contact_num[0]}}</span>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="form-label">Email Address *</label>
                                        <input type="text" class="form-control"  v-model="email_address"/>
                                         <span v-if="errors.email_address" class="text-danger">{{errors.email_address[0]}}</span>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div class="text-center mt-4">
                                <button type="button" class="btn btn-primary" id="btnSubmit" @click.prevent="submitButton(cooperative.id)">Add Officer</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4" v-for="(officer, j) in cooperative.officer">
                <div class="row text-center">
                    <div class="column">
                        <div class="content">
                            <img :src="officer.officer_pic == null?'/img/default_pic.jpg':'/upload/officer/'+officer.officer_pic" alt="Picture" style="max-width: 200px; max-height: 150px; line-height: 20px;">
                            <br/>
                            <strong>{{officer.prefix}} {{officer.first_name}} {{officer.middle_name}} {{officer.last_name}}</strong>
                            <p class="no_space">{{officer.position.name}}</p>
                            <p class="no_space">{{officer.contact_num}}</p>
                            <p class="no_space">{{officer.email_address}}</p>
                        </div>
                        <div>
                            <button type="button" class="btn btn-info"  @click.prevent="viewButton(officer)">Edit</button>
                            <button type="button" class="btn btn-danger" id="btnDelete" @click.prevent="deleteButton(officer.id)">Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: ['cooperative'],
        data(){
            return{
                prefix:'',
                first_name:'',
                middle_name:'',
                last_name:'',
                position:'',
                contact_num:'',
                email_address:'',
                errors:[],
                positions:[],
                position_id:'',
                officer_pic:'',
                upload_pic_edit:''
            }
        },
        methods:{
            getOfficer(){
                axios.get('/api/profile').then(response => {
                    var officer = response.data.cooperative.officer
                    this.cooperative.officer = officer;
                });
            },
            handlePicUpload(id){
                this.officer_pic = this.$refs.officer_pic.files[0];
            },
            viewButton(data){
                this.$router.push('/director/view/'+data.id+'/'+data.slug)
            },
            submitButton(cooperative_id){
                $("#btnSubmit").html("Submitting ...").attr("disabled", true);
          
                let formData = new FormData();
                formData.append('cooperative_id', cooperative_id);
                formData.append('position_id', this.position_id);
                formData.append('officer_pic', this.officer_pic);
                formData.append('prefix', this.prefix);
                formData.append('first_name', this.first_name);
                formData.append('middle_name', this.middle_name);
                formData.append('last_name', this.last_name);
                formData.append('position', this.position);
                formData.append('contact_num', this.contact_num);
                formData.append('email_address', this.email_address);
                axios.post( '/api/officers',
                        formData,
                    {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then(response =>{
                    this.$refs.officer_pic.value = '';
                    this.officer_pic = '';
                    this.prefix = '';
                    this.first_name = '';
                    this.middle_name = '';
                    this.last_name = '';
                    this.position = '';
                    this.contact_num = '';
                    this.email_address = '';
                    this.errors = [];
                    $("#btnSubmit").html("Submit").attr("disabled", false);
                    this.getOfficer();
                }).catch(error=>{
                    if (error.response.status == 422) {
                        this.errors = error.response.data.errors
                    }
                    $("#btnSubmit").html("<i class='fa fa-times'></i> Please try again!").attr("disabled", false);
                });
            },
            deleteButton(officer_id){
                swal.fire({
                    title: "Do you want to delete this officer?",
                    showCancelButton: true,
                    confirmButtonText: `Yes`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        axios
                        .delete('/api/officers/'+officer_id)
                        .then(response => {
                            swal.fire("Item Deleted!", "", "success");
                            this.getOfficer();
                        })
                        .catch(error =>{
                            console.log(error);
                        })
                        
                    }
                });
            },
            getPosition(){
                axios.get('/api/positions').then(response => {
                    var position = response.data
                    this.positions = position;
                });
            },
        },
        mounted(){
            this.getPosition();
            this.getOfficer();
        },
    }
</script>