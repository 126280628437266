<template>
    <div>
    	<section class="wpm_title_ber">
		    <div class="wpm_opacity">
		        <div class="container">
		            <div class="row">
		                <div class="col-sm-12">
		                    <h1>Cooperatives</h1>
		                </div>
		            </div>
		        </div>
		    </div>
		</section>
		<section class="wpm_blog_area">
		    <div class="container">
		        <div class="row" v-for="(cooperative, i) in cooperatives">
		            <div class="col-sm-6">
		                <img :src="'/upload/cooperative/'+cooperative.cooperative_pic" alt="" class="fileupload-preview fileupload-exists thumbnail" style="max-width: 455px; height: 200px; line-height: 20px; margin-left: 15%; margin-right: auto;"/>
		                <div class="wpm_date">
		                    <img :src="'/upload/logo/'+cooperative.logo.file" alt="" class="thumbnail" style="max-width: 100px; max-height: 100px; margin-left: auto; margin-right: auto;"/>
		                </div>
		            </div>

		            <div class="col-sm-6">
		                <h3><a href="blogarticle.html">{{cooperative.name}}</a></h3>
		                <p class="blog_para see_more">{{cooperative.about_us}}
		                </p>

		                <button class="btn btn-default wpm_btn" type="submit">VISIT</button>
		            </div>
		        </div>
		      <!--   <div class="bottom_btn text-center">
		            <a class="btn btn-default" href="#" role="button"><</a>
		            <a class="btn btn-default" href="#" role="button">1</a>
		            <a class="btn btn-default" href="#" role="button">2</a>
		            <a class="btn btn-default" href="#" role="button">3</a>
		            <a class="btn btn-default" href="#" role="button">></a>
		        </div> -->
		    </div>
		</section>
    </div>
</template>
<script>
    export default {
        data(){
            return {
                cooperatives:[],
            }
        },
        mounted(){
            this.getCooperatives();
        },
        methods:{
            getCooperatives(){
                axios.get('/api/all_cooperatives').then((response)=>{
                    this.cooperatives = response.data;
                }).catch((error) =>{
                    console.log(error)
                })
            },
        }
    }
</script>