window.$ = window.jQuery = require('jquery');
window.WOW = require('wowjs').WOW;
window.bsCustomFileInput = require('bs-custom-file-input');
window.swal = swal;
window.token= localStorage.getItem('token');
window.axios = require('axios');


axios.defaults.baseURL = process.env.API_LOCATION
axios.defaults.headers.common['Authorization'] = "Bearer "+window.token;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.get['Content-Type'] = 'application/json';


import Vue from 'vue'
import VueRouter from 'vue-router'
import swal from 'sweetalert2'
import Select2 from 'v-select2-component';
Vue.component('Select2', Select2);
import Echo from 'laravel-echo'


window.Pusher = require('pusher-js');

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.MIX_PUSHER_APP_KEY,
    cluster: process.env.MIX_PUSHER_APP_CLUSTER,
    forceTLS: true
});


Vue.use(VueRouter)

import App from './views/App'
import Home from './views/Home'
import Cooperatives from './views/Cooperatives'
import ProductsServices from './views/ProductsServices'
import Description from './views/Description'
import Hello from './views/Hello'
import Login from './views/Login'
import Register from './views/Register'
import Reset from './views/Reset'

//Admin
import Dashboard from './views/Admin/Dashboard'

//Cooperative
import CoopProfile from './views/Coop/Profile'
import CoopProduct from './views/Coop/Product'
import CoopViewProduct from './views/Coop/ViewProduct'

import CoopProfileNew from './views/Coop/NewProfile'
import CoopProfileEdit from './views/Coop/EditProfile'

import CoopOfficer from './views/Coop/Officer'
import CoopViewOfficer from './views/Coop/ViewOfficer'

import ForgotPassword from './views/ForgotPassword'

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home,
            meta: {
                requiresVisitors: true
            }
        },
        {
            path: '/cooperatives',
            name: 'cooperatives',
            component: Cooperatives,
            props: true,
            meta: {
                requiresVisitors: true
            }
        },
        {
            path: '/products-services',
            name: 'products',
            component: ProductsServices,
            props: true,
        },
        {
            path: '/description',
            name: 'description',
            component: Description,
            props: true,
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
            meta: {
                requiresVisitors: true
            }
        },
        {
            path: '/register',
            name: 'register',
            component: Register,
            meta: {
                requiresVisitors: true
            }
        },
        {
            path: '/dashboard',
            name: 'dashboard',
            component: Dashboard,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/cooperative-profile',
            name: 'coop_profile',
            component: CoopProfile,
            props: true,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/cooperative-profile/edit',
            name: 'coop_profile_edit',
            component: CoopProfileEdit,
            props: true,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/cooperative-profile/new',
            name: 'coop_profile_new',
            component: CoopProfileNew,
            props: true,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/cooperative-product-service/view/:id/:slug',
            name: 'view_product',
            component: CoopViewProduct,
            props: true,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/cooperative-products',
            name: 'coop_product',
            component: CoopProduct,
            props: true,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/cooperative-officer',
            name: 'coop_officer',
            component: CoopOfficer,
            props: true,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/director/view/:id/:slug',
            name: 'officer',
            component: CoopViewOfficer,
            props: true,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/reset/:token',
            name: 'reset',
            props: true,
            component: Reset,
            meta: {
                requiresVisitors: true
            }
        },
         {
            path: '/forgot',
            name: 'forgot_password',
            component: ForgotPassword,
            meta: {
                requiresVisitors: true
            }
        },
    ],
});

const openRoutes=['login','home','register','cooperatives', 'reset', 'forgot'];
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (window.token == null) {
            next({
                name: 'login',
            })
        } else {
            next()
        }
    } else if (to.matched.some(record => record.meta.requiresVisitors)) {
        if (window.token) {
            next({
                name: 'home',
            })
        } else {
            next()
        }
    } else {
        if(openRoutes.includes(to.name) ){
            next();
        } else if(window.token){
            next();
        } else {
            next('/login');
        }
    }
})

export default router

const app = new Vue({
    el: '#app',
    components: { App },
    router,
});