<template>
    <div class="container">
        <section class="wpm_title_ber">
            <div class="wpm_opacity">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12">
                            <h1>Sign up</h1>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="row text-center" style="padding-top: 100px;">
            <div class="col-md-12">
               <h3>Cooperative Directory</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 col-md-offset-4 col-sm-6 col-sm-offset-3 col-xs-10 col-xs-offset-1">
                <div class="panel-body">
                    <form role="form">
                        <hr />
                        <h3 class="text-center">Register New Account</h3>
                        <br />
                        <span class="text-danger" v-if="errors.username">{{errors.username[0]}}</span>
                        <div class="form-group input-group">
                            <span class="input-group-addon"><i class="fa fa-user"></i></span>
                            <input type="text" class="form-control" placeholder="Your Username" v-model="form.username" />
                        </div>
                        <span class="text-danger" v-if="errors.email">{{errors.email[0]}}</span>
                        <div class="form-group input-group">
                            <span class="input-group-addon"><i class="fa fa-tag"></i></span>
                            <input type="text" class="form-control" placeholder="Your Email Address" v-model="form.email" />
                        </div>
                        <span class="text-danger" v-if="errors.password">{{errors.password[0]}}</span>
                        <div class="form-group input-group">
                            <span class="input-group-addon"><i class="fa fa-lock"></i></span>
                            <input type="password" id="password" class="form-control" placeholder="Your Password" v-model="form.password"/>
                        </div>
                        <span class="text-danger" v-if="errors.password_confirmation">{{errors.password_confirmation[0]}}</span>
                        <div class="form-group input-group">
                            <span class="input-group-addon"><i class="fa fa-unlock-alt"></i></span>
                            <input type="password" id="password_confirmation" class="form-control" placeholder="Confirm Password" v-model="form.password_confirmation" name="password_confirmation"/>
                        </div>
                       <!--  <div class="form-group">
                            <span class="pull-right">
                                <a href="index.html">Forget password ? </a>
                            </span>
                        </div> -->

                        <a href="index.html" class="btn btn-primary" id="btnRegister" @click.prevent="registerButton">Register Now</a>
                        <hr />
                        Already have account ? <router-link :to="{name:'login'}">click here </router-link>
                    </form>
                </div>
            </div>
        </div>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
    </div>
</template>
<script>
    export default {
        data(){
            return{
                form: {
                    username: '',
                    email: '',
                    password:'',
                    password_confirmation:'',
                    role_id:2
                },
                errors:[]
            }
        },
        methods:{
            registerButton(){
                $("#btnRegister").html("Submitting ...").attr("disabled", true);
                axios.post('api/register',this.form).then((response)=>{
                    this.form={};
                    swal.fire(
                        'Congratulations!',
                        'Please Login your new account',
                        'success'
                    );
                    this.$router.push('/login');
                    $("#btnRegister").html("Register").attr("disabled", false);
                }).catch((error) =>{
                    this.errors = error.response.data.errors;
                    swal.fire(
                        'Please check your fields!',
                        'Invalid or missing inputs',
                        'warning'
                    )
                    $("#btnRegister").html("Register").attr("disabled", false);
                })
            }
        }
    }
    
</script>