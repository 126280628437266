<template>
    <div class="container">
        <section class="wpm_title_ber">
            <div class="wpm_opacity">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12">
                            <h1>Sign in</h1>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="row text-center" style="padding-top: 100px;">
            <div class="col-md-12">
               <h3>Cooperative Directory</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 col-md-offset-4 col-sm-6 col-sm-offset-3 col-xs-10 col-xs-offset-1">
                <div class="panel-body">
                    <form role="form">
                        <hr />
                        <h3 class="text-center">Enter Your Registered Email</h3>
                        <br />
                        <span class="text-danger" v-if="errors.email">{{errors.email[0]}}</span>
                        <div class="form-group input-group">
                            <span class="input-group-addon"><i class="fa fa-tag"></i></span>
                            <input type="text" class="form-control" placeholder="Your Email Address" v-model="email" />
                        </div>
                        <a href="index.html" class="btn btn-primary" id="btnReset" @click.prevent="resetButton"> Reset Now</a>
                        <hr />
                        Not register ? <router-link :to="{name:'register'}">click here </router-link>
                    </form>
                </div>
            </div>
        </div>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
    </div>
</template>
<script>
    export default {
        data(){
            return{
                email:'',
                errors:[],
            }
        },
        methods:{
            resetButton(){
                $("#btnReset").html("Submitting ...").attr("disabled", true);
                axios({
                    method: 'POST',
                    url: '/api/password/email',
                    data: {
                        email: this.email,
                    }
                }).then(response=>{
                    $("#btnReset").html("Reset Now").attr("disabled", false);
                    swal.fire(
                        'Congratulations!',
                        'Passord reset link was sent to your email',
                        'success'
                    );
                    this.$router.push('/login');
                }).catch(error =>{
                    console.log(error);
                    if (error.response) {
                        this.errors = error.response.data.errors
                        swal.fire(
                            'Please check your fields!',
                            'Invalid email or missing inputs',
                            'warning'
                        )
                    } else {
                        alert('internal error please contact the Admin')
                    }
                $("#btnReset").html("Reset Now").attr("disabled", false);
                })
            }
        }
    }
</script>