<template>
   <!--  <div class="container-fluid mt-5">
        <h2 class="h2 text-center mt-5 mb-3">{{name}}</h2>
        <div class="container">
           <div class="row wow fadeIn">
                <div class="col-md-6 mb-4">
                    <div class="p-4">
                        <p class="lead font-weight-bold">Description</p>
                        <textarea class="form-control" rows="25" v-model="description"></textarea>
                    </div>
                </div>
                 <div class="col-md-6 mb-4">
                    <div class="p-4">
                        <p class="lead">
                            <span>Downloadable Files</span>
                            <input type="file" id="file" ref="file" v-on:change="handleFileUpload()" single/> <button class="btn btn-success xs" v-on:click="submitFile()" id="btnUploadFile">Upload</button>
                        </p>
                        <div v-if="files.length > 0">
                            <a  v-for="(file, i) in files" :href="'/upload/file/'+file.file" :download="file.name">{{file.name}} </a>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <div id="page-inner">
        <div class="col-md-12">
            <h1 class="page-head-line">Products and Services</h1>
            <h1 class="page-subhead-line">* are required fields </h1>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="panel panel-info">
                    <div class="panel-heading">
                        Update {{product.name}}
                    </div>
                    <div class="panel-body">
                        <form role="form">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label class="form-label">Product/Service Name</label>
                                        <input type="text"  class="form-control" v-model="product.name"/>
                                        <span v-if="errors.name" class="text-danger">{{errors.name[0]}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label class="form-label" >Description</label>
                                        <textarea class="form-control" rows="20" v-model="product.description"></textarea>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div class="text-center mt-4">
                                <router-link type="button" class="btn btn-warning" :to="{ name: 'coop_product'}">Back</router-link>
                                <button type="submit" class="btn btn-info btn-md" id="btnUpdate" @click.prevent="updateButton(product.id)">Update</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="panel panel-primary">
                    <div class="panel-heading">
                        Add downloadable files/forms
                    </div>
                    <div class="panel-body">
                        <form role="form">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label class="form-label">File Name</label>
                                        <input type="text"  class="form-control" v-model="file_name"/>
                                        <span v-if="errors.file_name" class="text-danger">{{errors.file_name[0]}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label class="form-label">Upload File</label>
                                        <input type="file" id="file" ref="file" class="form-control" v-on:change="handleFileUpload()" single/>
                                        <span v-if="errors.name" class="text-danger">{{errors.name[0]}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="text-center mt-4">
                                <button type="submit" class="btn btn-primary btn-md" id="btnUploadFile" @click.prevent="submitFile(product.id)">Upload</button>
                            </div>
                        </form>
                        <br/>
                        <div v-if="files.length > 0">
                            <div class="list-group">
                                    <ul>
                                        <li v-for="(file, i) in files" class="list-group-item">
                                            <i class="fa fa-file"></i> {{file.file_name}}
                                            
                                            <a class="btn btn-sm pull-right" @click.prevent="deleteFile(file.id)"> <i class="fa fa-trash text-danger"></i> Delete</a>
                                            <a class="btn btn-sm pull-right" :href="'/upload/file/'+file.file" :download="file.file_name"> <i class="fa fa-download text-success"></i> Download</a>
                                        </li>
                                    </ul>
                            </div>
                        </div>
                        <div v-else>
                            <p class="mute">No uploaded Files</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props:['id','slug'],
        data(){
            return{
                product:{
                    name:'',
                    description:'',
                },
                file:'',
                file_name:'',
                files:[],
                errors:[],
                products:[],
                product_id:''
            }
        },
        methods:{
            getProduct(){
                axios.get('/api/product/'+this.id+'/'+this.slug).then(response => {
                    this.product = response.data;
                    this.files = response.data.file;
                })
            },
            handleFileUpload(){
                this.file = this.$refs.file.files[0];
            },
            updateButton(product_id){
                $("#btnUpdate").html("Updating ...").attr("disabled", true);
          
                axios.put( '/api/products/'+product_id, this.product,
                ).then(response =>{
                    $("#btnUpdate").html("Update").attr("disabled", false);
                    swal.fire(
                        'Congratulations!',
                        'You successfully updated the item',
                        'success'
                    )
                }).catch(error=>{
                    if (error.response.status == 422) {
                        this.errors = error.response.data.errors
                    }
                    $("#btnUpdate").html("<i class='fa fa-times'></i> Please try again!").attr("disabled", false);
                });
            },
            submitFile(product_id){
                $("#btnUploadFile").html("<div class='overlay'><i class='fa fa-refresh fa-spin'></i></div>").attr("disabled", true);
                this.product_id = product_id;
                let formData = new FormData();
                formData.append('file', this.file);
                formData.append('file_name', this.file_name);
                formData.append('product_id',  this.product_id);
                axios.post( '/api/files',
                        formData,
                    {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then(response =>{
                    this.$refs.file.value = '';
                    this.file_name = '';
                    $("#btnUploadFile").html("Upload").attr("disabled", false);
                    this.getProduct();
                }).catch(error=>{
                    if (error.response.status == 422) {
                        this.errors = error.response.data.errors
                    }
                    $("#btnUploadFile").html("<i class='fa fa-times'></i> Something went wrong, Please try again!").attr("disabled", false);
                });
            },
            deleteFile(file_id){
                swal.fire({
                    title: "Do you want to delete this item?",
                    showCancelButton: true,
                    confirmButtonText: `Yes`,
                }).then((result) => {
                    if (result.isConfirmed) {
                         axios.delete('/api/files/'+file_id).then(response =>{
                            swal.fire("Item Deleted!", "", "success");
                            this.getProduct();
                        }).catch(error =>{
                            console.log(error)
                        });
                        
                    }
                });
               
            },
        },
        mounted(){
            this.getProduct();
        },
    }
</script>