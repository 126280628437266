<template>
 	<div>
        <section class="wpm_welcome_area">
            <div class="container">
                <div class="row">
                    <div class="col-sm-2"></div>
                    <div class="col-sm-8">
                        <img src="img/baguioseal.png" alt="baguio logo">
                        <img src="img/cdaseal.png" alt="cooperative dev. authority logo">
                        <h1>Baguio Cooperative<span class="wpm_bold"> Directory</span></h1>
                        <p>The City Government of Baguio and Cooperative Development Authority - Cordillera Extension Office</p>
                    </div>
                    <div class="col-sm-2"></div>
                </div>
            </div>
        </section>
        <section class="wpm_creative_area">
            <div class="container">
                <div class="row">
                    <div class="col-sm-6">
                        <h2>About this <span class="wpm_bold">Site</span></h2>
                        <p>
                            Through this cooperative directory, interested individual or group may browse this site containing the products and services of our different cooperatives located in Baguio City.
                            This is targeted to assists our cooperatives in marketing their products and services and also to increase the awareness of our constituents regarding the benefits that they may get from cooperatives. 
                        </p>
                    </div>
                    <div class="col-sm-6">
                        <img src="img/portfolio/coop.jpg" style="width: 100%; max-width: 100%; height: auto; display: block;"/>
                    </div>
                </div>
            </div>
        </section>
        <section class="wpm_feature_area">
            <div class="wpm_opacity">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-4">
                            <i class="fa fa-home color1"></i>
                            <h3>Home</h3>
                        </div>
                        <div class="col-sm-4">
                            <i class="fa fa-institution color2"></i>
                            <h3>Cooperatives</h3>
                        </div>
                        <div class="col-sm-4">
                            <i class="fa fa-opencart color3"></i>
                            <h3>Products and Services</h3>
                        </div>
                    </div>
                </div>
            </div>
        </section>
            <section class="wpm_contact_area">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12 text-center">
                            <h2>Cooperative Development Authority - Cordillera Extension Office</h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <h3>Contact Information</h3>
                            <div class="contact_text">
                                <i class="fa fa-mobile"></i>
                                <p>
                                    PHONE:<br />
                                    012 250 00 013
                                </p>
                                <div class="clearfix"></div>
                            </div>
                            <div class="contact_text">
                                <i class="fa fa-envelope-o"></i>
                                <p>
                                    EMAIL:<br />
                                    mail@example.com
                                </p>
                                <div class="clearfix"></div>
                            </div>
                            <div class="contact_text">
                                <i class="fa fa-map-marker"></i>
                                <p>
                                    ADDRESS:<br />
                                    Type Your Address
                                </p>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <h3>Drop us a message</h3>
                            <form>
                                <input type="text" class="form-control" placeholder="Name" />
                                <input type="email" class="form-control" id="exampleInputEmail3" placeholder="Enter email" />
                                <textarea class="form-control" rows="5" placeholder="Write Massage"></textarea>
                                <input class="btn btn-default" type="submit" value="SUBMIT" />
                            </form>
                        </div>
                    </div>
                </div>
        </section>
 	</div>
</template>
<script>
    export default {
        data(){
            return {
                types:[],
                type_id:''
            }
        },
        mounted(){
            //this.getTypes();
        },
        methods:{
            // getTypes(){
            //     axios.get('/api/types').then((response)=>{
            //         this.types = response.data
            //     }).catch((error) =>{
            //         console.log(error)
            //     })
            // },
        }
  }
</script>