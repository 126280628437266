<template>
    <div id="page-inner">
        <div class="col-md-12">
            <h1 class="page-head-line">Board of Directors</h1>
            <h1 class="page-subhead-line">* are required fields </h1>
        </div>
        <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="panel panel-info">
                    <div class="panel-heading">
                       Update {{officer.prefix}} {{officer.first_name}} {{officer.middle_name}} {{officer.last_name}} Details
                    </div>
                    <div class="panel-body">
                        <form role="form">
                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <div class="text-center">
                                            <div class="form-group text-center" v-if="officer.officer_pic != null">
                                                <img :src="officer.officer_pic == null?'':'/upload/officer/'+officer.officer_pic" alt="Picture" style="max-width: 200px; max-height: 150px; line-height: 20px;"/>
                                                <button class="btn btn-danger" v-on:click="removePic(officer.id)" id="btnRemovePic">Remove</button>
                                            </div>
                                            <div class="form-group" v-else>
                                                <div class="text-center">
                                                    <div class="fileupload fileupload-new" data-provides="fileupload">
                                                        <div class="fileupload-new thumbnail" style="width: 200px; height: 150px;"><img src="/img/demoUpload.jpg" alt="" /></div>
                                                        <div class="fileupload-preview fileupload-exists thumbnail" style="max-width: 200px; max-height: 150px; line-height: 20px;"></div>
                                                       <div>
                                                    <span class="btn btn-file btn-primary"><span class="fileupload-new">Select image</span><span class="fileupload-exists">Change</span><input type="file" id="officer_pic" ref="officer_pic" v-on:change="handlePicUpload()" single/></span>
                                                    <a class="btn btn-info fileupload-exists" id="btnPicUpdate" @click.prevent="picButtonUpload(officer.id)">Upload</a>
                                                    <a href="#" class="btn btn-danger fileupload-exists" data-dismiss="fileupload">Remove</a>
                                                    <span v-if="errors.officer_pic" class="text-danger">{{errors.officer_pic[0]}}</span>
                                                </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label class="form-label">Prefix *</label>
                                        <input type="text" class="form-control" placeholder="eg. Mr./Mrs./Atty./Engr. etc." v-model="officer.prefix" />
                                         <span v-if="errors.prefix" class="text-danger">{{errors.prefix[0]}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label class="form-label">First Name *</label>
                                        <input type="text" class="form-control" v-model="officer.first_name"/>
                                         <span v-if="errors.first_name" class="text-danger">{{errors.first_name[0]}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">   
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label class="form-label">Middle Initial</label>
                                        <input type="text" class="form-control"  v-model="officer.middle_name"/>
                                         <span v-if="errors.middle_name" class="text-danger">{{errors.middle_name[0]}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label class="form-label">Last Name *</label>
                                        <input type="text" class="form-control"  v-model="officer.last_name"/>
                                         <span v-if="errors.last_name" class="text-danger">{{errors.last_name[0]}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12"">
                                    <div class="form-group">
                                        <label class="form-label">Position *</label>
                                        <select class="form-control" v-model="officer.position_id">
                                            <option value="" disabled selected>Select</option>
                                            <option v-for="(position, i) in positions" :value="position.id">{{position.name}}</option>
                                        </select>
                                        <span v-if="errors.position" class="text-danger">{{errors.position[0]}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                 <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">Contact Number *</label>
                                        <input type="text" class="form-control"  v-model="officer.contact_num"/>
                                         <span v-if="errors.contact_num" class="text-danger">{{errors.contact_num[0]}}</span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">Email Address *</label>
                                        <input type="text" class="form-control"  v-model="officer.email_address"/>
                                         <span v-if="errors.email_address" class="text-danger">{{errors.email_address[0]}}</span>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div class="text-center mt-4">
                                <router-link type="button" class="btn btn-warning" :to="{ name: 'coop_officer'}">Back</router-link>
                                <button type="button" class="btn btn-info" id="btnUpdate" @click.prevent="updateButton(officer.id)">Update</button>
                            </div>                              
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props:['id','slug'],
        data(){
            return{
                officer:{
                    prefix:'',
                    first_name:'',
                    middle_name:'',
                    last_name:'',
                    position:'',
                    contact_num:'',
                    email_address:'',
                    position_id:'',
                },
                errors:[],
                positions:[],
                upload_pic_edit:'',
            }
        },
        mounted(){
            this.getOfficer();
            this.getPosition();
        },
        methods:{
            getOfficer(){
                axios.get('/api/officer/'+this.id+'/'+this.slug).then(response => {
                    var officer = response.data
                    this.officer = officer;
                });
            },
            handlePicUpload(){
                this.officer_pic = this.$refs.officer_pic.files[0];
            },
            updateButton(officer_id){
                $("#btnUpdate").html("Updating ...").attr("disabled", true);
          
                axios.put( '/api/officers/'+officer_id, this.officer,
                ).then(response =>{
                    $("#btnUpdate").html("Update").attr("disabled", false);
                    swal.fire(
                        'Congratulations!',
                        'You successfully updated the details',
                        'success'
                    )
                }).catch(error=>{
                    if (error.response.status == 422) {
                        this.errors = error.response.data.errors
                    }
                    $("#btnUpdate").html("<i class='fa fa-times'></i> Please try again!").attr("disabled", false);
                });
            },
            picButtonUpload(officer_id){
                $("#btnPicUpdate").html("Updating ...").attr("disabled", true);
          
                let formData = new FormData();
                formData.append('officer_pic', this.officer_pic);
                axios.post( '/api/officers/updatepic/'+officer_id,
                        formData,
                    {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then(response =>{
                    $("#btnPicUpdate").html("Update").attr("disabled", false);
                    swal.fire(
                        'Congratulations!',
                        'You successfully uploaded the image',
                        'success'
                    )
                    this.getOfficer();
                }).catch(error=>{
                    if (error.response.status == 422) {
                        this.errors = error.response.data.errors
                    }
                    $("#btnPicUpdate").html("<i class='fa fa-times'></i> Please try again!").attr("disabled", false);
                });
            },
            removePic(officer_id){
                $("#btnRemovePic").html("Removing ...").attr("disabled", true);
                axios.put( '/api/officers/removepic/'+officer_id)
                .then(response =>{
                    $("#btnRemovePic").html("Submit").attr("disabled", false);
                    this.getOfficer();
                })
            },
            getPosition(){
                axios.get('/api/positions').then(response => {
                    var position = response.data
                    this.positions = position;
                });
            },
        }
    }
</script>