<template>
    <div id="page-inner">
        <div class="row">
            <div class="col-md-12">
                <h1 class="page-head-line">Register Cooperative</h1>
                <h1 class="page-subhead-line">* are required fields </h1>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="panel panel-primary">
                    <div class="panel-heading">
                       Cooperative Details
                    </div>
                    <div class="panel-body">
                        <form role="form">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label class="form-label" >Cooperative Name</label>
                                        <input type="text"  class="form-control" v-model="name"/>
                                        <span v-if="errors.name" class="text-danger">{{errors.name[0]}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="form-label">CDA Registration Number</label>
                                        <input type="text" class="form-control" v-model="cda"/>
                                        <span v-if="errors.cda" class="text-danger">{{errors.cda[0]}}</span>
                                    </div>
                                </div>
                                <div class="col-md-4"">
                                    <div class="form-group">
                                        <label class="form-label">CIN Number</label>
                                        <input type="text"  class="form-control" v-model="cin"/>
                                        <span v-if="errors.cin" class="text-danger">{{errors.cin[0]}}</span>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="form-label">Type of Cooperative</label>
                                        <select class="form-control" v-model="type_id">
                                            <option value="" disabled selected>Choose Type</option>
                                            <option v-for="(type, i) in types" :value="type.id">{{type.name}}</option>
                                        </select>
                                        <span v-if="errors.type_id" class="text-danger">{{errors.type_id[0]}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">BIR CTE Validity</label>
                                        <input type="date" class="form-control" v-model="bir"/>
                                        <span v-if="errors.bir" class="text-danger">{{errors.bir[0]}}</span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">TIN</label>
                                        <input type="text"  class="form-control" v-model="tin"/>
                                        <span v-if="errors.tin" class="text-danger">{{errors.tin[0]}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label class="form-label" >About Us</label>
                                        <textarea class="form-control" rows="6" v-model="about_us"></textarea>
                                    </div>
                                </div>
                            </div>
                            <h4>Additional Information</h4>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">Number of Staff</label>
                                        <input type="text" class="form-control" v-model="no_staff"/>
                                        <span v-if="errors.no_staff" class="text-danger">{{errors.no_staff[0]}}</span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">Number of Volunteers</label>
                                        <input type="text"  class="form-control" v-model="no_volunteers"/>
                                        <span v-if="errors.no_volunteers" class="text-danger">{{errors.no_volunteers[0]}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">Address</label>
                                        <input type="text" class="form-control" v-model="location" placeholder="Location" />
                                        <span v-if="errors.location" class="text-danger">{{errors.location[0]}}</span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">Select Barangay</label>
                                        <Select2 v-model="barangay_id" :options='barangays'></Select2>
                                        <span v-if="errors.barangay_id" class="text-danger">{{errors.barangay_id[0]}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">Contact Number</label>
                                        <input type="text" class="form-control" v-model="contact_no"/>
                                        <span v-if="errors.contact_no" class="text-danger">{{errors.contact_no[0]}}</span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">Email Address</label>
                                        <input type="email"  class="form-control" v-model="email"/>
                                        <span v-if="errors.email" class="text-danger">{{errors.email[0]}}</span>
                                    </div>
                                </div>
                            </div>
                            <h4>Others</h4>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                       <textarea class="form-control" rows="6" v-model="others"></textarea>
                                    </div>
                                </div>
                            </div>
                            <h4>Upload image of your cooperative building</h4>
                            <label class="page-subhead-line">This image will be displayed on the home page of our cooperative website</label>
                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <div class="text-center">
                                            <div class="fileupload fileupload-new" data-provides="fileupload">
                                                <div class="fileupload-new thumbnail" style="width: 200px; height: 150px;"><img src="/img/demoUpload.jpg" alt="" /></div>
                                                <div class="fileupload-preview fileupload-exists thumbnail" style="max-width: 200px; max-height: 150px; line-height: 20px;"></div>
                                                <div>
                                                    <span class="btn btn-file btn-primary"><span class="fileupload-new">Select image</span><span class="fileupload-exists">Change</span><input type="file" id="cooperative_pic" ref="cooperative_pic" v-on:change="handlePicUpload()" single/></span>
                                                    <a href="#" class="btn btn-danger fileupload-exists" data-dismiss="fileupload">Remove</a>
                                                    <span v-if="errors.cooperative_pic" class="text-danger">{{errors.cooperative_pic[0]}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" class="btn btn-primary btn-block"  @click.prevent="submitButton(auth.id)" id="btnSubmit">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: ['cooperative','auth'],
        data(){
            return{
                name:'',
                status:1,
                type_id:'',
                description:'',
                cda:'',
                cin:'',
                bir:'',
                tin:'',
                banner:'',
                logo:'',
                no_staff:'',
                no_volunteers:'',
                contact_no:'',
                email:'',
                barangay_id:'',
                location:'',
                others:'',
                about_us:'',
                user_id:'',
                cooperative_pic:[],
                errors:[],
                barangays:[],
                types:[],
                
            }
        },
        mounted(){
            this.getBarangays();
            this.getTypes();
        },
        methods:{
            handlePicUpload(){
                this.cooperative_pic = this.$refs.cooperative_pic.files[0];
            },
            getBarangays(){
                axios.get('/api/barangays').then(response => {
                    this.barangay = response.data
                    var data = this.barangay.map(function(item){
                        return {
                            id : item.id,
                            text: item.name
                        }
                    }.bind(this));
                    this.barangays = data
                })
            },
            getTypes(){
                axios.get('/api/types').then((response)=>{
                    this.types = response.data
                }).catch((error) =>{
                    console.log(error)
                })
            },
            submitButton(user_id){
                
                // $("#btnSubmit").html("Submitting ...").attr("disabled", true);
                // axios.post('/api/cooperatives', this.form).then((response)=>{
                //     var coop = response.data;
                //     swal.fire(
                //         'Congratulations!',
                //         'You successfully added the cooperative details',
                //         'success'
                //     )
                // $("#btnSubmit").html("Submit").attr("disabled", false);
                // window.location.href='/cooperative-profile'
                // }).catch(error => {
                //     console.log(error)
                //     if (error.response.status == 422) {
                //         this.errors = error.response.data.errors
                //         $("#btnSubmit").html("<i class='fa fa-times'></i> Something went wrong, Please try again!").attr("disabled", false);
                //     }
                // })
                $("#btnSubmit").html("Submitting ...").attr("disabled", true);
          
                let formData = new FormData();
                formData.append('user_id', user_id);
                formData.append('name', this.name);
                formData.append('status', this.status);
                formData.append('type_id', this.type_id);
                formData.append('description', this.description);
                formData.append('cda', this.cda);
                formData.append('cin', this.cin);
                formData.append('bir', this.bir);
                formData.append('tin', this.tin);
                formData.append('banner', this.banner);
                formData.append('logo', this.logo);
                formData.append('no_staff', this.no_staff);
                formData.append('no_volunteers', this.no_volunteers);
                formData.append('contact_no', this.contact_no);
                formData.append('email', this.email);
                formData.append('barangay_id', this.barangay_id);
                formData.append('location', this.location);
                formData.append('others', this.others);
                formData.append('about_us', this.about_us);
                formData.append('cooperative_pic', this.cooperative_pic);
                axios.post( '/api/cooperatives',
                        formData,
                    {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then(response =>{
                    swal.fire(
                        'Congratulations!',
                        'You successfully added the cooperative details',
                        'success'
                    )
                    $("#btnSubmit").html("Submit").attr("disabled", false);
                    window.location.href='/cooperative-profile'
                }).catch(error=>{
                    if (error.response.status == 422) {
                        this.errors = error.response.data.errors
                    }
                    $("#btnSubmit").html("<i class='fa fa-times'></i> Something went wrong, Please try again!").attr("disabled", false);
                });
            }
        }
    }
</script>
