<template>
    <div id="page-inner" v-if="cooperative != null"> 
        <div class="col-md-12">
            <h1 class="page-head-line">{{cooperative.name}}</h1>
            <h1 class="page-subhead-line">Upload your cooperative banner and logo here</h1>
        </div>
        <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="panel panel-default" v-if="banner_pics.file != null">
                    <div id="carousel-example" class="carousel slide" data-ride="carousel" >
                        <div class="carousel-inner">
                            <div v-for="(banner_pic, i) in banner_pics.file" :key="i" :class="i==0?'item active':'item'">
                                <img :src="'/upload/banner/'+banner_pic" alt="" style="margin-left: auto; margin-right: auto;"/>
                            </div>
                        </div>
                        <ol class="carousel-indicators">
                            <li data-target="#carousel-example" v-for="(banner_pic, j) in banner_pics.file" :key="j" :data-slide-to="j" :class="j==0?'active':''"></li>
                        </ol>
                        <a class="left carousel-control" href="#carousel-example" data-slide="prev">
                            <span class="glyphicon glyphicon-chevron-left"></span>
                        </a>
                        <a class="right carousel-control" href="#carousel-example" data-slide="next">
                            <span class="glyphicon glyphicon-chevron-right"></span>
                        </a>
                    </div>
                    <div class="text-center">
                         <button class="btn btn-danger" v-on:click="deleteBanner(cooperative.id)" id="btnRemoveBanner">Remove Banner</button>
                    </div>
                </div>
                <div class="form-group" v-else>
                    <div class="text-center">
                        <div class="fileupload fileupload-new" data-provides="fileupload">
                            <div class="fileupload-new thumbnail" style="width: 200px; height: 150px;"><img src="assets/img/demoUpload.jpg" alt="" /></div>
                            <div class="fileupload-preview fileupload-exists thumbnail" style="max-width: 200px; max-height: 150px; line-height: 20px;"></div>
                            <div class="row">
                                <div class="col-md-4 d-flex justify-content-end">Select Banner: </div>
                                <div class="col-md-8">
                                    <input type="file" id="banner" ref="banner" multiple v-on:change="handleBannerUpload()"/>
                                </div>
                                <span v-if="errors.banners" class="text-danger">{{errors.banners[0]}}</span>
                                <button class="btn btn-success" v-on:click="submitBanner(cooperative.id)" id="btnUploadBanner">Upload Banner</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">  
                <div class="text-center" v-if="cooperative.logo == null">
                    <div class="fileupload fileupload-new" data-provides="fileupload">
                        <div class="fileupload-new thumbnail" style="width: 200px; height: 150px;"><img src="/img/demoUpload.jpg" alt="" /></div>
                        <div class="fileupload-preview fileupload-exists thumbnail" style="max-width: 200px; max-height: 150px; line-height: 20px;"></div>
                        <div>
                            <span class="btn btn-file btn-primary"><span class="fileupload-new">Select logo</span><span class="fileupload-exists">Change</span><input type="file" id="logo" ref="logo" v-on:change="handleLogoUpload()" single/></span>
                            <button class="btn btn-success fileupload-exists" v-on:click="submitLogo(cooperative.id)" id="btnUploadLogo">Upload</button>
                             <span v-if="errors.logo" class="text-danger">{{errors.logo[0]}}</span>
                        </div>
                    </div>
                </div>
                <div  v-else>
                    <img :src='"/upload/logo/"+cooperative.logo.file' class="img-fluid" alt="" style="max-width: 160px; max-height: 160px;"/>
                    <button class="btn btn-danger" v-on:click="deleteLogo(cooperative.id)" id="btnRemoveLogo">Remove Logo</button>
                </div>
                <p><i>CDA: Reg. No.</i> {{cooperative.cda}}</p>
                <p><i>CIN:</i> {{cooperative.cin}}</p>
                <p><i>BIR: CTE Validity </i> {{cooperative.bir}}</p>
                <p><i>TIN:</i> {{cooperative.tin}}</p>
            </div>
            <div class="col-md-8">
                <h3 class="text-center"><span>About Us</span></h3>
                <p class="line" style="text-indent: 45px; text-align: justify;">{{cooperative.about_us}}</p>
            </div>
        </div>
        <div class="row">
            <div class="text-center">
                <h3><span>Featured Products and Services</span></h3>
            </div>
            <div class="col-md-12" v-if="cooperative.product != ''">
                <div class="col-lg-4 col-md-6" v-for="(product, i) in cooperative.product">
                    <div class="alert alert-warning text-center simple-table">
                        <i class="fa fa-check-circle fa-5x"></i>
                        <h3>{{product.name}}</h3>
                        <p class="dark-grey-text">{{product.description}}</p>
                        <br/>
                    </div>
                </div>
            </div>
            <div class="col-md-12" v-else>
                <p class="lead text-center">Coming Soon</p>
            </div>
        </div>
        <div class="row">
            <div class="text-center">
                <h3><span>Board of Directorss</span></h3>
            </div>
            <div class="col-sm-4" v-for="(officer, j) in cooperative.officer">
                <div class="row text-center">
                    <div class="column">
                        <div class="content">
                            <img :src="officer.officer_pic == null?'/img/default_pic.jpg':'/upload/officer/'+officer.officer_pic" alt="Picture" style="max-width: 200px; max-height: 150px; line-height: 20px;">
                            <br/>
                            <strong>{{officer.prefix}} {{officer.first_name}} {{officer.middle_name}} {{officer.last_name}}</strong>
                            <p class="no_space">{{officer.position.name}}</p>
                            <p class="no_space">{{officer.contact_num}}</p>
                            <p class="no_space">{{officer.email_address}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid mt-5 mb-5 text-center" v-else>
        <h2 class="h2 text-center mt-5 mb-3">Welcome, {{auth.username}}</h2>
        <p>Through this cooperative directory, interested individual or group may browse this site containing the products and services of our different cooperatives located in Baguio City. This is targeted to assists our cooperatives in marketing their products and services and also to increase the awareness of our constituents regarding the benefits that they may get from cooperatives.</p>
        <h4>To start with,<strong> <router-link :to="{ name:'coop_profile_new'}"> Please click here to register your cooperative details</router-link></strong></h4>
    </div>
</template>
<script>
    export default {
        props: ['cooperative','banner_pics','auth'],
        data(){
            return{
                name:'',
                status:1,
                type:'',
                description:'',
                cda:'',
                cin:'',
                bir:'',
                tin:'',
                banners:[],
                no_staff:'',
                no_volunteers:'',
                contact_no:'',
                email:'',
                barangay:'',
                location:'',
                others:'',
                about_us:'',
                errors:[],
                barangays:[],
                types:[],
                user_id:'',
                cooperative_id:'',
                products:[],
                product:'',
                officers:[],
                officer:'',
            }
        },
        methods:{
            handleLogoUpload(){
                this.logo = this.$refs.logo.files[0];
            },
            getProducts(){
                axios.get('/api/profile').then(response => {
                    var product = response.data.cooperative.product
                    this.cooperative.product = product;
                })
            },
            submitLogo(cooperative_id){
                $("#btnUploadLogo").html("<div class='overlay'><i class='fa fa-refresh fa-spin'></i></div>").attr("disabled", true);
                var self = this;
                let formData = new FormData();
                formData.append('logo', this.logo);
                formData.append('cooperative_id',cooperative_id);
                axios.post( '/api/logos',
                        formData,
                    {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then(response =>{
                    self.$refs.logo.value = '';
                    $("#btnUploadLogo").html("Remove Logo").attr("disabled", false);
                    this.cooperative.logo = response.data;
                }).catch(error=>{
                    console.log(error)
                    if (error.response.status == 422) {
                        this.errors = error.response.data.errors
                    }
                    $("#btnUploadLogo").html("<i class='fa fa-times'></i> Something went wrong, Please try again!").attr("disabled", false);
                });
            },
            deleteLogo(cooperative_id){
                $("#btnRemoveLogo").html("<div class='overlay'><i class='fa fa-refresh fa-spin'></i></div>").attr("disabled", true);
                axios.delete('/api/logos/'+cooperative_id).then(response =>{
                    $("#btnRemoveLogo").html("Upload").attr("disabled", false);
                    this.cooperative.logo = null
                }).catch(error =>{
                    console.log(error)
                    if (error.response.status == 422) {
                        this.errors = error.response.data.errors
                    }
                    $("#btnRemoveLogo").html("<i class='fa fa-times'></i> Something went wrong, Please try again!").attr("disabled", false);
                });
            },
            submitBanner(cooperative_id){
                $("#btnUploadBanner").html("<div class='overlay'><i class='fa fa-refresh fa-spin'></i></div>").attr("disabled", true);
                var self = this;

                let formData = new FormData();
                formData.append('cooperative_id', cooperative_id);
                for (let i =0; i<this.banners.length; i++){
                    
                    let file = self.banners[i];
                    formData.append('banners['+ i + ']', file);

                }

                const config ={
                    headers:{"content-type" : "multipart/form-data"}
                }
                axios.post('/api/banners', formData, config).then(response => {
                    $("#btnUploadBanner").html("Remove").attr("disabled", false);
                    self.$refs.banner.value = '';
                    self.banners = [];
                    this.banner_pics.file = JSON.parse(response.data.file);
                }).catch(error => {
                    console.log(error)
                    // if (error.response.status == 422) {
                    //     this.errors = error.response.data.errors
                    // }
                    $("#btnUploadBanner").html("<i class='fa fa-times'></i> Something went wrong, Please try again!").attr("disabled", false);
                })
            },
            deleteBanner(cooperative_id){
                $("#btnRemoveBanner").html("<div class='overlay'><i class='fa fa-refresh fa-spin'></i></div>").attr("disabled", true);
                axios.delete('/api/banners/'+cooperative_id).then(response =>{
                    $("#btnRemoveBanner").html("Upload").attr("disabled", false);
                    this.banner_pics.file = null
                }).catch(error =>{
                    if (error.response.status == 422) {
                        this.errors = error.response.data.errors
                    }
                    $("#btnRemoveBanner").html("<i class='fa fa-times'></i> Something went wrong, Please try again!").attr("disabled", false);
                });
            },
            handleBannerUpload(){
                for (let i=0; i < this.$refs.banner.files.length; i++){
                    this.banners.push(this.$refs.banner.files[i]);
                }
            },
            deleteButton(coop_id){
                swal.fire({
                    title: "Do you want to delete this item?",
                    showCancelButton: true,
                    confirmButtonText: `Yes`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        axios
                        .delete('/api/products/'+coop_id)
                        .then(response => {
                            swal.fire("Item Deleted!", "", "success");
                            this.getProducts();
                        })
                        .catch(error =>{
                            console.log(error);
                        })
                        
                    }
                });
            },
            viewButton(data){
                this.$router.push('/cooperative-product-service/view/'+data.id+'/'+data.slug)
            }
        }
    }
</script>
