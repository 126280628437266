<template>
    <div v-if="auth != null">
        <div id="wrapper">
            <nav class="navbar navbar-default navbar-cls-top " role="navigation" style="margin-bottom: 0">
                <div class="navbar-header">
                    <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".sidebar-collapse">
                        <span class="sr-only">Toggle navigation</span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                    </button>
                    <router-link class="navbar-brand" :to="{ name: 'coop_profile'}">
                        COOPERATIVE
                    </router-link>
                </div>
                <div class="header-right">
                    <a class="btn btn-danger" title="Logout" @click.prevent="logout"><i class="fa fa-power-off fa-2x"></i></a>
                </div>
            </nav>
            <nav class="navbar-default navbar-side" role="navigation">
                <div class="sidebar-collapse">
                    <ul class="nav" id="main-menu">
                        <li>
                            <div class="user-img-div">
                                <img src="/img/default_pic.jpg" class="img-thumbnail" />
                                <div class="inner-text">
                                    {{auth.username}}
                                </div>
                            </div>
                        </li>
                        <li>
                            <router-link :to="{ name: 'coop_profile'}">
                                <i class="fa fa-home "></i>Home
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'coop_profile_new'}" v-if="cooperative == null"> 
                                <i class="fa fa-info-circle"></i>Cooperative Details
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'coop_profile_edit'}" v-if="cooperative != null"> Cooperative Details
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'coop_product'}" v-if="cooperative != null"> 
                                Products & Services
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'coop_officer'}" v-if="cooperative != null"> 
                                Board of Directors
                            </router-link>
                        </li>
                    </ul>

                </div>

            </nav>
            <div id="page-wrapper">
                <router-view :coop_id="coop_id" :cooperative="cooperative" :banner_pics="banner_pic" :auth="auth"></router-view>
            </div>
        </div>
        <div id="footer-sec">
            &copy; <a href="https://baguio.gov.ph/" target="_blank">2021 City Government of Baguio - Management Information Technology Division</a>
        </div>
    </div>
    <div v-else>
        <header class="wpm_header">
            <div class="container">
                <div class="row">
                    <div class="col-sm-9">
                        <nav class="navbar navbar-default wpm_navber">
                            <div class="navbar-header">
                                <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1">
                                    <span class="sr-only">Toggle navigation</span>
                                    <span class="icon-bar"></span>
                                    <span class="icon-bar"></span>
                                    <span class="icon-bar"></span>
                                </button>
                                <router-link :to="{ name: 'home'}">
                                    <h5 class="text-white"><strong>BAGUIO COOPERATIVE DIRECTORY</strong></h5>
                                </router-link>
                            </div>
                            <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                                <ul class="nav navbar-nav navbar-right wpm_menu">
                                    <li><router-link :to="{ name: 'home'}">Home</router-link></li>
                                    <li><router-link :to="{ name: 'cooperatives'}">Cooperatives</router-link></li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </header>
        <div>
            <router-view :coop_id="coop_id" :cooperative="cooperative" :banner_pics="banner_pic" :auth="auth"></router-view>
        </div>
        <div id="footer-sec">
            &copy; <a href="https://baguio.gov.ph/" target="_blank">2021 City Government of Baguio - Management Information Technology Division</a>
        </div>
    </div>
</template>
<script>
    export default {
        data(){
            return {
                auth:{
                    username:''
                },
                coop_id:[],
                products:[],
                data:[],
                cooperative:{
                    barangay:{
                        name:''
                    },
                    logo:{
                        file:'logo'
                    },
                    name:'',
                    status:1,
                    type_id:'',
                    description:'',
                    cda:'',
                    cin:'',
                    bir:'',
                    tin:'',
                    no_staff:'',
                    no_volunteers:'',
                    contact_no:'',
                    email:'',
                    barangay_id:'',
                    location:'',
                    others:'',
                    about_us:'',
                },
                banner_pic:{
                    file:'banner'
                },
            }
        },
        mounted(){
            this.getProfile();
        },
        methods:{
            logout(){
                axios
                    .post("/api/logout")
                    .then((response) => {
                        localStorage.removeItem('token');
                        window.location.href='/'
                    })
                    .catch((error) => {
                        this.errors = error.response.data.errors;
                    });
            },
            getProfile(){
                if(token){
                    axios.get('/api/profile').then(response => {
                        this.auth=response.data
                        this.cooperative=response.data.cooperative
                        var banner=this.cooperative==null?null:this.cooperative.banner == null ? null: response.data.cooperative.banner.file;
                        this.banner_pic.file=JSON.parse(banner);
                    })
                } else {
                    this.auth = null;
                }
            },
        }

  }
</script>