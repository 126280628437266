<template>
    <div class="container">
        <section class="wpm_title_ber">
            <div class="wpm_opacity">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12">
                            <h1>Sign in</h1>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="row text-center" style="padding-top: 100px;">
            <div class="col-md-12">
               <h3>Cooperative Directory</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 col-md-offset-4 col-sm-6 col-sm-offset-3 col-xs-10 col-xs-offset-1">
                <div class="panel-body">
                    <form role="form">
                        <hr />
                        <h3 class="text-center">Enter Details to Login</h3>
                        <br />
                        <span class="text-danger" v-if="errors.email">{{errors.email[0]}}</span>
                        <div class="form-group input-group">
                            <span class="input-group-addon"><i class="fa fa-tag"></i></span>
                            <input type="text" class="form-control" placeholder="Your Email Address" v-model="form.email" />
                        </div>
                        <span class="text-danger" v-if="errors.password">{{errors.password[0]}}</span>
                        <div class="form-group input-group">
                            <span class="input-group-addon"><i class="fa fa-lock"></i></span>
                            <input type="password" class="form-control" placeholder="Your Password" v-model="form.password"/>
                        </div>

                        <div class="form-group">
                            <span class="pull-right">
                                <router-link :to="{name:'forgot_password'}">Forgot password ? </router-link>
                            </span>
                        </div>

                        <a href="index.html" class="btn btn-primary" id="btnLogin" @click.prevent="loginButton">Login Now</a>
                        <hr />
                        Not register ? <router-link :to="{name:'register'}">click here </router-link>
                    </form>
                </div>
            </div>
        </div>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
    </div>
</template>
<script>
    export default {
        data(){
            return{
                form: {
                    email: '',
                    password:'',
                },
                errors:[],
                unauthorized:''
            }
        },
        methods:{
            loginButton(){
                $("#btnLogin").html("Submitting ...").attr("disabled", true);
                axios.post('/api/login',this.form).then((response)=>{
                    let accessToken=response.data.auth.access_token;
                    localStorage.setItem('token', accessToken);
                    window.location.href='/cooperative-profile'
                    $("#btnLogin").html("Login Now").attr("disabled", false);
                }).catch((error) =>{
                    if (error.response) {
                        this.errors = error.response.data.errors
                        swal.fire(
                            'Please check your fields!',
                            'Invalid or missing inputs',
                            'warning'
                        )
                    } else if (error){
                        swal.fire(
                            'Please check your credentials!',
                            'Invalid e-mail or password',
                            'warning'
                        )
                    }
                    $("#btnLogin").html("Login Now").attr("disabled", false);
                })
            }
        }
    }
</script>