<template>
    <div id="page-inner">
        <div class="col-md-12">
            <h1 class="page-head-line">Products and Services</h1>
            <h1 class="page-subhead-line">* are required fields </h1>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="panel panel-primary">
                    <div class="panel-heading">
                        Add Product or Service
                    </div>
                    <div class="panel-body">
                        <form role="form">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label class="form-label">Product/Service Name</label>
                                        <input type="text"  class="form-control" v-model="product_name"/>
                                        <span v-if="errors.name" class="text-danger">{{errors.name[0]}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label class="form-label" >Description</label>
                                        <textarea class="form-control" rows="6" v-model="product_description"></textarea>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div class="text-center mt-4">
                                <button type="submit" class="btn btn-primary btn-md" id="btnSubmit" @click.prevent="submitButton(cooperative.id)">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="col-lg-4 col-md-6" v-for="(product, i) in cooperative.product">
                    <div class="alert alert-warning text-center simple-table">
                        <i class="fa fa-check-circle fa-5x"></i>
                        <h3>{{product.name}}</h3>
                        <p class="dark-grey-text">{{product.description}}</p>
                        <br/>
                        <button type="button" class="btn btn-primary"  @click.prevent="viewButton(product)">View</button>
                        <button type="button" class="btn btn-danger" id="btnDelete" @click.prevent="deleteButton(product.id)">Delete</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: ['cooperative'],
        data(){
            return{
                product_name:'',
                product_description:'',
                errors:[],
                product_types:[],
            }
        },
        methods:{
            getProducts(){
                axios.get('/api/profile').then(response => {
                    var product = response.data.cooperative.product
                    this.cooperative.product = product;
                })
            },
            submitButton(coop_id){
                $("#btnSubmit").html("Submitting ...").attr("disabled", true);
                axios({
                    method: 'POST',
                    url: '/api/products',
                    data: {
                        name: this.product_name,
                        description: this.product_description,
                        cooperative_id: coop_id
                    }
                }).then(response => {
                    $("#btnSubmit").html("Submit").attr("disabled", false);
                    swal.fire(
                        'Congratulations!',
                        'You successfully added the item',
                        'success'
                    )
                    this.product_name='';
                    this.product_description='';
                    this.getProducts();
                }).catch(error=>{
                    console.log(error)
                    if (error.response.status == 422) {
                        this.errors = error.response.data.errors
                        $("#btnSubmit").html("<i class='fa fa-times'></i> Please try again!").attr("disabled", false);
                    }
                })
            },
            deleteButton(coop_id){
                swal.fire({
                    title: "Do you want to delete this item?",
                    showCancelButton: true,
                    confirmButtonText: `Yes`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        axios
                        .delete('/api/products/'+coop_id)
                        .then(response => {
                            swal.fire("Item Deleted!", "", "success");
                            this.getProducts();
                        })
                        .catch(error =>{
                            console.log(error);
                        })
                        
                    }
                });
            },
            viewButton(data){
                this.$router.push('/cooperative-product-service/view/'+data.id+'/'+data.slug)
            }
        },
        mounted(){
            this.getProducts();
        }
    }
</script>